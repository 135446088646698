<template>
  <div class="about">
    <div class="grid-container">
      <div class="grid">
        <div class="quote">
          „Ich schaue gerne Filme und mache noch lieber welche. Ich sehe überall Licht, auch im Schatten. Ich mag
          einfache Technik und komplizierte Menschen. Manchmal auch andersherum.“
        </div>
        <div>
          <img class="img-fluid" alt="jan" src="@/assets/jan-roedger.jpg" />
        </div>

        <div class="copy">
          <h4>Über mich</h4>
          <p>
            Ein herausragendes Merkmal meiner Arbeit ist der Fokus auf intimer, dokumentarischer Inszenierung gepaart
            mit hochästhetischer, kinematografischer Darstellung. Dabei rücke ich meine Protagonist:innen ins Zentrum,
            immer auf der Suche nach den stärksten Bildern für ihre Geschichte.
          </p>
          <p>
            Ich bin als Filmemacher mit dem gesamten Produktionsprozess vertraut. Ich liebe Teamwork in Departments,
            tauche aber auch genauso gut in die intime Produktionsumgebung dokumentarischer Aufnahmen ab. Die
            Abwechslung hält den Blick frisch!
          </p>
          <p>
            Das Feedback meiner Auftraggeber und Kollegen:innen ist eindeutig: Gelassen, lösungsorientiert, einfühlsam,
            humorvoll und dennoch äußerst professionell.
          </p>
          <p>Lasst uns gemeinsam gute Filme machen!</p>
        </div>
        <div class="equipment">
          <h4>Equipment</h4>
          <ul>
            <li>Canon Cinema EOS C400 (Vollformat)</li>
            <li>Canon Cinema EOS R5C (Vollformat)</li>
            <li>Leica R Cine Festbrennweiten & Zooms</li>
            <li>Canon RF Zooms & Festbrennweiten</li>
            <li>div. Spezialobjektive wie Fisheye, Tilt-Shift, Lensbaby</li>
            <li>Videofunkstrecke CineEye 2s Pro und 10" Regiemonitor</li>
          </ul>
          <ul>
            <li>DJI RS3 Pro</li>
            <li>DJI Mini 3 Pro</li>
          </ul>
          <ul>
            <li>Licht von Aputure und F&V</li>
            <li>div. Grip und Lichtformer</li>
            <li>Ton von Sennheiser</li>
          </ul>
          <ul>
            <li>Produktionsfahrzeug</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
};
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.quote {
  font-size: 1.5rem;
  font-style: italic;
  line-height: 1.5;
  align-self: center;
  text-align: center;
}

ul {
  list-style: none;
  padding: 0;
}
</style>
